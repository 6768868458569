import React, {useEffect} from 'react';
import Layout from '~components/layout';
import Seo from '~components/seo';
import PageLink from '~containers/page-link';
import {firebaseAnalytics} from '~utils/firebase';

// page-slug
const Info = props => {
  useEffect(() => {
    firebaseAnalytics.logEvent('Info_page_visited');
  }, []);

  const {
    params: {pageInfo},
  } = props;
  return (
    <Layout>
      <Seo title='Info' />
      <PageLink link={pageInfo} isAbout={false} />
    </Layout>
  );
};

export default Info;
